// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-data-privacy-index-js": () => import("./../src/pages/data-privacy/index.js" /* webpackChunkName: "component---src-pages-data-privacy-index-js" */),
  "component---src-pages-dpraward-index-js": () => import("./../src/pages/dpraward/index.js" /* webpackChunkName: "component---src-pages-dpraward-index-js" */),
  "component---src-pages-for-brands-index-js": () => import("./../src/pages/for-brands/index.js" /* webpackChunkName: "component---src-pages-for-brands-index-js" */),
  "component---src-pages-for-content-creators-index-js": () => import("./../src/pages/for-content-creators/index.js" /* webpackChunkName: "component---src-pages-for-content-creators-index-js" */),
  "component---src-pages-for-readers-index-js": () => import("./../src/pages/for-readers/index.js" /* webpackChunkName: "component---src-pages-for-readers-index-js" */),
  "component---src-pages-impressum-index-js": () => import("./../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-medwatch-before-js": () => import("./../src/pages/landing-page-medwatch/before.js" /* webpackChunkName: "component---src-pages-landing-page-medwatch-before-js" */),
  "component---src-pages-landing-page-medwatch-index-js": () => import("./../src/pages/landing-page-medwatch/index.js" /* webpackChunkName: "component---src-pages-landing-page-medwatch-index-js" */),
  "component---src-pages-landing-page-piqd-index-js": () => import("./../src/pages/landing-page-piqd/index.js" /* webpackChunkName: "component---src-pages-landing-page-piqd-index-js" */),
  "component---src-pages-media-kit-index-js": () => import("./../src/pages/media-kit/index.js" /* webpackChunkName: "component---src-pages-media-kit-index-js" */),
  "component---src-pages-old-home-index-js": () => import("./../src/pages/old-home/index.js" /* webpackChunkName: "component---src-pages-old-home-index-js" */),
  "component---src-pages-productdemo-index-js": () => import("./../src/pages/productdemo/index.js" /* webpackChunkName: "component---src-pages-productdemo-index-js" */),
  "component---src-pages-stay-tunned-index-js": () => import("./../src/pages/stay-tunned/index.js" /* webpackChunkName: "component---src-pages-stay-tunned-index-js" */),
  "component---src-pages-termsofservice-index-js": () => import("./../src/pages/termsofservice/index.js" /* webpackChunkName: "component---src-pages-termsofservice-index-js" */),
  "component---src-pages-ycombinator-index-js": () => import("./../src/pages/ycombinator/index.js" /* webpackChunkName: "component---src-pages-ycombinator-index-js" */)
}

